/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

// Meta Component
import Meta from 'components/Meta'

// Work Component
import WorkTemplate from 'routes/templates/work'

export default WorkTemplate

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.datoCmsPage.seoMetaTags.tags} />
}

export const query = graphql`
  query WorkQuery($slug: String!) {
    datoCmsPage(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      excerpt
      content
      featuredMedia {
        url
        fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_tracedSVG
        }
      }
    }
  }
`
