import PropTypes from 'prop-types'
import React from 'react'
import { Section, Container, Columns } from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'

function WorkTemplate({ data }) {
  return (
    <Section>
      <SiteNav fullLogo />
      <Container className="content">
        <Columns>
          <Columns.Column className="is-offset-1 is-10">
            <div
              dangerouslySetInnerHTML={{
                __html: data.datoCmsPage.content,
              }}
            />
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

WorkTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default WorkTemplate
